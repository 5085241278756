.home-carousel {
  // margin-top: 107px;
  .sc-EHOje {
    margin: 0px;
  }

  .sc-htpNat {
    div {
      width: 16px !important;
      height: 8px !important;
      border-radius: 2px !important;
    }
  }

  .kVtMtA,
  .bhRnqO {
    background: #a3cd39 !important;
    height: 70px !important;
    top: 50% !important;
    transform: translate(0, -50%) !important;
  }
  .kVtMtA {
    border-radius: 0px 5px 5px 0px !important;
  }
  .bhRnqO {
    border-radius: 5px 0px 0px 5px !important;
  }
  .kVtMtA::before,
  .bhRnqO:before {
    border-top: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
  }

  .div1 {
    background: #ededed;
    // background-size: 100% 100%;
    height: 600px;
    width: 100%;
    z-index: 1;
    text-align: center;
    // background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // box-shadow: 10px 0 5px -2px #c2171d;
    p {
      // border: 1px solid #fff;
      // padding: 5px 28px;

      color: #fff;
      font-weight: 300;
      // margin-top: 68px;
      font-size: 26px;
      letter-spacing: 5px;
      width: -moz-fit-content;
      width: fit-content;
    }

    .shop-btn {
      padding-top: 1.5rem !important;
      border: 0px;
      text-align: left;
      background: #a3cd39;
      color: #3a3a3a;
      font-weight: 900;
      padding: 5px 28px;
      letter-spacing: 3px;
      border-radius: 3px;
      width: 560px;
      position: absolute;
      bottom: 130px;
      transform: translate(-50%, 0px);
      transition: transform 0.45s cubic-bezier(0.25, 0, 0, 1),
        box-shadow 0.45s cubic-bezier(0.25, 0, 0, 1);
      will-change: box-shadow;
      &:hover {
        // transform: translate3D(0, -2px, 0);
      }
    }
  }

  .div2 {
    background: no-repeat 0px -5px;
    opacity: 1;
    background-size: 100% 100%;
    height: 205px;
    width: 50%;
  }
}

.shop-now-section {
  margin-top: 43px;

  .shop-btn {
    border: 0px;
    background: #c2171d;
    font-weight: 700;
    color: #fff;
    padding: 5px 28px;
    border-radius: 3px 0px 0px 3px;
    position: absolute;
    bottom: 20px;
    right: 15px;
    box-shadow: 0 0.5rem 1.5rem #c2171d26;
    cursor: pointer;
    transition: transform 0.45s cubic-bezier(0.25, 0, 0, 1),
      box-shadow 0.45s cubic-bezier(0.25, 0, 0, 1);
    will-change: box-shadow;
    &:hover {
      transform: translate3D(0, -2px, 0);
      background: #b51c22;
    }
  }

  .view-catalogue {
    border: 0px;
    background: #fff;
    font-weight: 700;
    color: #000;
    padding: 5px 28px;
    border-radius: 3px 0px 0px 3px;
    position: absolute;
    bottom: 20px;
    right: 0px;
    &:hover {
      transform: translate3D(0, -2px, 0);
      background: #fff;
    }
  }
}

.main-section {
  .side-menu {
    border: 1px solid #e3e3e3;
    border-radius: 5px;

    .shop-dept {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      padding: 8px 14px;
      background: #c2171d;
      border-radius: 5px;
    }

    .accordion {
      .card {
        border-bottom: 1px solid #e3e3e3;

        .card-header {
          border-top: 0px !important;
          padding: 0px 15px;
          font-weight: 500 !important;
          font-size: 16px;
        }
      }
    }
  }

  .catalogue-card .card-title {
    color: #c2171d !important;
  }

  .catalogue-card {
    .left-box,
    .right-box {
      .card-title {
        color: #b2b2b2 !important;
      }
    }

    .card-body {
      height: 180px;
    }
  }
}

.sub-image {
  position: relative;

  .electrical-label {
    position: absolute;
    top: 50%;
    font-weight: 900;
    transform: translate(0, -50%);
    right: 115px;
    text-transform: uppercase;
    color: #fff;
    font-size: 30px;
  }
}

.stay-update {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  background: #3a3a3a;
  padding: 30px 20px 30px 0px;
  @media all and (max-width: 991px) {
    display: block;
  }

  .inner-one {
    // background: #000;
    text-align: start;
    color: white;
    padding: 10px 20px;
    border-radius: 0px 5px 5px 0px;
    max-width: 600px;
    width: 100%;

    p {
      font-size: 45px;
      font-weight: 700;
      margin-bottom: 0px;
      text-transform: uppercase;
      @media all and (max-width: 576px) {
        font-size: 40px;
      }
    }

    span {
      color: $app-secondary-color;
      font-weight: 700;
      font-size: 27px;
      @media all and (max-width: 576px) {
        font-size: 22px;
      }
    }
  }

  .inner-two {
    align-self: flex-end;
    @media all and (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
    }

    form {
      display: flex;
      @media all and (max-width: 991px) {
        display: block;
      }

      .auth-btn {
        background: $app-secondary-color;
        border-radius: 5px;
        color: white;
        padding: 0px 30px;
        border: 1px solid #000;

        &:hover {
          background: transparent;
          color: #fff;
          border: 1px solid #000;
        }
      }
    }

    margin-left: 20px;

    .full-width {
      height: 40px;
      margin-top: 20px;
      font-size: 15px !important;
    }

    .sub-input {
      margin-right: 20px;
      margin-bottom: 0px;
      margin-top: 20px;
      width: 300px;
      @media all and (max-width: 991px) {
        margin-right: 0;
      }

      input {
        font-size: 15px !important;
        border-radius: 5px !important;
        height: 40px !important;
        border: 0px !important;
      }
    }
  }
}

.brochure {
  .row {
    display: flex;
    flex-wrap: wrap;

    // margin: 0px !important;
    // margin-left: -15px !important;
    .catalogue {
      padding-right: 0px !important;
    }

    .special {
      padding-left: 15px !important;
    }
  }

  .col {
    .bg-div {
      display: flex;
      flex-wrap: wrap;
      background: #c2171d;
      width: 100%;

      .inner-one {
        background: #000;
        text-align: left;
        color: white;
        padding: 10px 30px;
        border-radius: 5px;
        width: 250px;
        margin: 30px 0px 30px 0px;

        p {
          font-size: 35px;
          font-weight: 700;
          margin-bottom: 0px;
          text-transform: uppercase;
        }

        span {
          font-weight: 700;
          font-size: 24px;
        }
      }

      .inner-two {
        img {
          max-width: 284px;
          height: 100%;
        }
      }
    }
  }
}

.help-details {
  margin-bottom: 60px;
  // .help-detail-row {
  //   height: 11.5rem;
  // }
  .inner-div {
    // border-radius: 5px;
    background-color: $app-secondary-color;
    display: flex;
    padding: 20px 10px;
    padding-top: 2.188rem !important;
    padding-bottom: 3.938rem !important;
    svg {
      width: 60px;
      height: 40px;
      transform: scale(1.5);
      margin-left: 20px;
      margin-top: 10px;
    }

    label {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      width: 100%;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: normal !important;
    }
  }
}

.payment-options {
  display: flex;
  // border: 3px solid #a3cd39;
  flex-wrap: wrap;
  margin-bottom: 60px;
  // background-color: #fff;
  border-radius: 5px;
  @media all and (max-width: 1199px) {
    display: block;
  }

  .payment {
    // background: #c2171d;
    padding: 8px 5px 8px 20px;
    // color: #fff;
    font-weight: 700;
    border-radius: 0px 5px 5px 0px;
    width: 20%;
    margin: 16.77px 0;
    @media all and (max-width: 1199px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      width: fit-content;
      padding: 8px 20px 8px 20px;
    }
  }

  .options {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 0px 20px;
    @media all and (max-width: 1199px) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 40px;
      row-gap: 20px;
      margin-top: 25px;
      padding: 0;
    }
    @media all and (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
    }

    img {
      height: 30px;
      @media all and (max-width: 1199px) {
        height: auto;
      }
    }
  }
}

.home-footer {
  background: #c2201c;

  .cust-container {
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
    text-transform: uppercase;
    padding: 44px 0px;
    align-items: center;
    @media all and (max-width: 575px) {
      padding: 15px 0px;
    }
    .text-ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0px;
      row-gap: 0px;
      padding-left: 0;
      margin-bottom: 0;
      width: 100%;
      @media all and (max-width: 991px) {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        position: relative;
        text-align: left;
        list-style: none;
        text-transform: uppercase;
        margin: 0px 20px;
        div {
          font-family: Lato;
          letter-spacing: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-weight: 700;
          line-height: 20px;
          height: 100%;
          color: #ffffff;
          @media all and (max-width: 1650px) {
            font-size: 22px;
            line-height: 22px;
          }
          @media all and (max-width: 1500px) {
            font-size: 18px;
            line-height: 18px;
          }
          @media all and (max-width: 991px) {
            justify-content: center !important;
            padding: 15px 0;
          }
          img {
            margin-left: 6px;
            @media all and (max-width: 991px) {
              width: 30px;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: -21px;
          width: 2px;
          height: 81px;
          background-color: #fff;
          bottom: 0;
          margin: auto 0;
          @media all and (max-width: 991px) {
            height: 2px;
            width: 50%;
            left: 0;
            bottom: -1px;
            top: unset;
            right: 0;
            margin: 0 auto;
          }
          @media all and (max-width: 575px) {
            width: 70%;
          }
        }
        &:first-child {
          text-align: left;
          div {
            letter-spacing: 5.2px !important;
            justify-content: flex-start;
          }
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
          div {
            letter-spacing: 5.2px;
            justify-content: flex-end;
          }
          &::before {
            display: none;
          }
        }
      }
    }
    .footer-line {
      border-left: 3px solid;
      height: 81px;
    }

    div {
      letter-spacing: 5.2px;
      font-weight: 700;
      font-size: 20px;
    }

    ul {
      display: flex;

      margin-bottom: 0px;

      .shop-dot {
        list-style: none;
      }

      li {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 5.2px;
        margin-left: 45px;
      }
    }
  }
}

.footer-section {
  background: #3a3a3a;

  .footer {
    background: #3a3a3a;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;

    ul {
      list-style-type: none;
      color: #fff;

      li {
        font-size: 12px;
        padding: 5px 0px;
      }
    }

    p {
      text-align: center;
      color: #fff;
      font-size: 26px !important;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .image-section {
      display: flex;

      li {
        background: #fff;
        border-radius: 50%;
        padding: 13px 14px;
        width: 50px;
        height: 50px;
        margin-right: 12px;

        .fa {
          color: #c2171d;
          font-size: 24px;
        }
      }
    }

    .logo {
      height: 78px;
      padding: 1rem 0.625rem;
      background: white;
      border-radius: 5px;
    }

    .contact-us {
      align-self: center;
      display: flex;

      .contact {
        background: $app-secondary-color;
        color: #fff;
        padding: 12px;
        font-weight: 700;
        border-radius: 5px 0px 0px 5px;
        text-transform: uppercase;
      }

      button {
        background-color: #d3d3d3;
        border-radius: 0px 5px 5px 0px;
        border: 0px;
        padding: 10px;
        font-size: 30px;
        font-weight: 900;
      }
    }

    .first-li {
      font-size: 16px !important;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.copy-right {
  background: #53903e;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 12px;
}

.equipment-section {
  margin-top: 50px;
  margin-bottom: 50px;

  p {
    color: #c2171d;
    font-size: 22px;
    font-weight: 700;
  }

  p.details {
    text-transform: none;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
  }

  .promotion-img {
    border: 1px solid #707070;
    height: 150px;
  }

  .details-section {
    p {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      color: #000000;
      padding-top: 10px;
    }

    span {
      color: #c2171d;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}

// First Nav
.first-nav {
  background: #fff;
  @media all and (max-width: 991px) {
    display: none;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    list-style-type: none;

    a {
      color: #006938;
      font-weight: 700;
      font-size: 14px;
      @media all and (max-width: 1050px) {
        padding: 0.5rem 0.5rem;
      }
    }
  }
}

// Contactus
.contact-banner {
  background: url(../images/news-banner.png) no-repeat 0px -5px;
  opacity: 1;
  background-size: 100% 100%;
  height: 350px;
  position: relative;

  .inner-bg {
    background-color: #000;
    opacity: 0;
    height: 345px;
  }

  .title {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    font-weight: 700;
    font-size: 30px;
    width: 790px;
  }
}

.carousel {
  .cart-img {
    width: auto !important;
    height: auto !important;
    border: 0px !important;
  }
}

.border-div {
  // border-bottom: 2px solid #3f3f3f;
  margin: 0px 80px;
}

// Latest News
// Contactus
.about-banner {
  .title {
    top: 134px !important;
  }
}

.franchise-banner {
  background: url(../images/franchise.png) no-repeat 0px -5px !important;

  .inner-bg {
    opacity: 0 !important;
  }
}

.gallery-banner {
  background: url(../images/acdc-office.png) no-repeat 0px -5px !important;

  .inner-bg {
    opacity: 0 !important;
  }
}

.news-banner {
  background: no-repeat 0px -5px;
  opacity: 1;
  background-size: 100% 100%;
  height: 350px;
  position: relative;

  .inner-bg {
    background-color: #000;
    opacity: 0;
    height: 350px;
  }

  .title {
    position: absolute;
    top: 78px;
    color: white;
    font-weight: 700;
    font-size: 35px;
    padding-left: 6.5%;
  }
}

.news-section {
  iframe {
    width: 100% !important;
  }
  margin-top: 30px;
  .photo-galary-grid {
    img {
      margin-bottom: 30px;
    }
  }
  .title {
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: #3a3a3a;
    }
  }
}

.news-accordion {
  .shop-dept {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    padding: 8px 14px;
    background: #c2171d;
    border-radius: 5px;
  }

  .card {
    border: 0px !important;
  }

  .card-header {
    border-radius: 5px !important;
    background-color: #f8f7f7 !important;
    border: 0px !important;

    span {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
    }

    .news-header {
      color: #c2171d;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

// Product style
.new-btns {
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #3f3f3f;
  color: #fff;
  line-height: 40px;
  padding: 0 10px;
  // font-family: $font-bold;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px solid #3f3f3f;
  min-width: 189px !important;

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: #3f3f3f;
  }

  &.full-width {
    width: 100%;
    min-width: auto;
  }
}

.catalogue-section {
  .side-menu {
    border: 1px solid #eae8e8;
    border-radius: 5px;
    // position: sticky;
    top: 170px;
    background-color: #fff;
    padding: 9px;
  }
}

.sale-div {
  position: relative;

  .shop-btn {
    border: 0px;
    background: #c2171d;
    font-weight: 700;
    color: #fff;
    padding: 5px 28px;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.5s ease-out;
    will-change: box-shadow;
    &:hover {
      transform: translate3D(0, -2px, 0);
      background: #b51c22;
      transition: all 0.5s ease-out;
    }
  }
}

.auth-header {
  padding-bottom: 0px !important;
}

.brochure-details {
  .brochure-col {
    .row {
      border: 1px solid #eae8e8;
      // padding: 10px;
    }
  }

  h2 {
    color: #3f3f3f;
    font-size: 20px;
    font-weight: 700;
  }

  div.details {
    span {
      color: #000000;
      font-size: 16px;
      min-width: 50px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      color: #000000;
    }
  }

  .offer {
    font-size: 16px;

    color: #000000;
  }

  .view-btn {
    border-radius: 2px;
    background-color: #c2171d;
    border: none;
    // padding: 3px 41px;
    color: white;

    img {
      padding: 3px 10px;
      padding-right: 0px !important;
    }

    span {
      padding: 3px 36px;
      font-weight: 700;
    }
  }
}

.sale-badge {
  background: #c2171d;
  color: #fff;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  padding: 14px 8px;
  font-weight: 900;
  font-size: 12px;
}

.quote-table {
  .description-col {
    min-width: 250px;
  }

  td {
    img {
      min-width: 100px;
      border-radius: 5px;
      border: 1px solid #d3d3d3;
    }
  }

  thead,
  tbody {
    border: 1px solid #3f3f3f;
  }

  th,
  td {
    border-bottom: 1px solid #3f3f3f !important;
  }

  tfoot {
    th {
      border: none !important;
    }

    td {
      border: 1px solid #3f3f3f !important;
    }
  }
}

.quote-sidebar {
  li {
    text-transform: uppercase;
  }

  .select-option {
    border-radius: 5px;
    background-color: #3a3a3a;
    color: $app-secondary-color !important;
    border-bottom: 0px !important;
    font-size: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: 45px !important;
    line-height: 34px !important;
  }
}

.quote-menu {
  margin-top: 37px;
}

.change-quote {
  p {
    font-size: 14px;
    // font-weight: 300;
    color: #000;
  }
}

.print-btn {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: $app-secondary-color;
  color: #fff;
  border: 0px;
}

.quote-subtitle {
  display: flex;

  h2 {
    width: 70%;
    color: $app-secondary-color;
    font-size: 27px;
    font-weight: 700;
    text-align: center;
  }

  ul {
    list-style-type: none;
    width: 30%;

    li {
      display: flex;
      font-size: 14px;
    }

    .set-width {
      font-weight: 900;
      min-width: 120px;
      text-align: right;
    }
  }
}

.view-order {
  margin-top: 150px;
  text-align: center;

  h2 {
    color: $app-secondary-color;
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  p {
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  span {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }

  .buttons {
    margin-top: 64px;
  }
}

.reset-pass {
  p {
    width: 299px;
    height: 52px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 22px;
  }

  .form {
    margin-top: 3rem;
  }

  h2 {
    color: #3f3f3f;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .form-control {
    border: 1px solid #3f3f3f;
    padding-left: 20px !important;
  }

  button {
    width: 100%;
    max-width: 302px;
    height: auto;
    min-height: 40px;
    &.max-288 {
      max-width: 288px;
    }
  }
}

.login-register {
  p {
    color: #000000;
    font-size: 14px;
    // font-weight: 900;
  }

  h3 {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }

  .form-control {
    border-radius: 5px !important;
    height: 60px !important;
    margin-bottom: 37px;
  }

  .forgot-pass {
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 300;
  }
  .separator,
  .reset-pass .separator {
    margin: 37px 0px;
  }

  .fa {
    color: $app-secondary-color;
    left: 0 !important;
  }

  .outline-button {
    &.with-icon {
      padding: 0.375rem 2rem 0.375rem 2rem !important;
    }
    span {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding-left: 0px;
      text-align: center;
    }

    &:hover {
      span {
        color: white !important;
      }
    }
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  max-width: 350px;
  width: 100%;
  color: #3f3f3f;
  font-size: 18px;
  font-weight: 700;
  @media all and (max-width: 575px) {
    max-width: 250px !important;
  }
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #3f3f3f;
}

.separator:not(:empty)::before {
  margin-right: 2em;
}

.separator:not(:empty)::after {
  margin-left: 2em;
}

.store-locator {
  margin-left: auto;

  button {
    background: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-left: 8px;

    &:hover {
      color: #c2171d !important;
    }
  }
}
.content-page.catalogue-section {
  padding-top: 52px !important;
}

.table-responsive {
  border-radius: 5px;
}

.contact-body {
  h2 {
    margin-bottom: 10px;
  }
}

.account-div,
.main-cart {
  // align-self: flex-end;
  cursor: pointer;
}

.user-code {
  top: 74% !important;

  img {
    transform: rotate(90deg);
  }
}

.new-dropdown {
  top: 0 !important;
  left: -199px !important;
}
.item-dropdown {
  top: 0px !important;
  left: 0px !important;
}
.contact-location {
  transform: scale(2.5);
}

// reset password
.reset-password {
  .cust-control {
    margin-bottom: 25px !important;
  }

  .form-group {
    .ctrl-inner-wrapper {
      .form-control {
        height: 60px;
      }
    }
  }
}

// Cart
.cart-subtitle {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 35px;
}

.cart-table {
  tbody {
    tr td {
      border-bottom: 2px solid #b2b2b2 !important;
    }
  }
}

.cart-col {
  min-width: 160px !important;

  img {
    margin: 19px 0px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    object-fit: contain;
  }
}

.text-red {
  color: #c2171d !important;
  font-size: 16px;
  font-weight: 700;
}

.cart-address {
  .title {
    background-color: #3f3f3f;
    padding: 22px 10px;

    h2 {
      color: #ffffff;
      font-family: Lato;
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 0px;
    }
  }

  .description {
    border: 1px solid #b2b2b2;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    padding: 22.52px 10px;
  }

  .description-two {
    border: 1px solid #b2b2b2;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    padding: 41.1px 10px;
    margin-top: 20px;
  }
}

.cart-footer {
  input {
    border: 1px solid #000000;
    border-right: 0px;
    padding: 9px 10px;
    border-radius: 5px 0px 0px 5px;
    text-align: end;
  }

  button {
    border-radius: 5px;
    background-color: #c2171d;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 6px 10px;
    border: 0px;
  }
}

.cart-purchase {
  padding-bottom: 25px;

  span {
    color: #c2171d;
    font-size: 14px;
    font-weight: 900;
  }
}

.my-details {
  .my-details-welcome {
    p {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .account-info {
    .email {
      // width: 391px;
      padding-right: 16px;
    }

    h2 {
      border-bottom: 1px solid #3f3f3f;
      color: #3f3f3f;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 15px;
      @media all and (max-width: 1199px) {
        font-size: 18px;
      }
    }

    h3 {
      color: #3f3f3f;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      margin-top: 20px;
      @media all and (max-width: 1199px) {
        font-size: 18px;
      }
    }

    p {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }

    .reset-pass {
      text-decoration: underline;
      cursor: pointer;
    }

    .icon-edit {
      font-size: 22px;
      color: $app-secondary-color;
      cursor: pointer;
    }
  }

  .address-details {
    h2 {
      color: #3f3f3f;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 10px;
    }

    .blocks {
      display: flex;
      flex-wrap: wrap;

      div.main-block {
        // width: 391px;
        width: 100%;
        border: 1px solid #b2b2b2;

        .icon-edit {
          font-size: 22px;
          color: $app-secondary-color;
          cursor: pointer;
          padding: 16px;
        }
      }

      h2 {
        background-color: #3f3f3f;
        color: #ffffff;
        font-size: 14px;
        font-weight: 900;
        padding: 22px 16px;
      }
    }

    p {
      padding: 16px;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.store-selector {
  margin-top: 76px;

  h2 {
    color: #3f3f3f;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  .title {
    color: #c2171d;
    font-size: 14px;
    font-weight: 700;
  }

  svg {
    transform: rotate(90deg);
  }

  .new-dropdown {
    top: -29px !important;
  }
}

.brands {
  .brands-header {
    p {
      color: #c2171d;
      font-size: 22px;
      font-weight: 700;
    }

    border-bottom: 2px solid #3f3f3f;
  }

  .brands-body {
    padding: 28px 0px;
  }
}

.address-input {
  .form-control {
    height: 60px !important;
  }
}

.billing-subtitle {
  color: #000000;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 900;
}

.billing-subtitle ~ div {
  span {
    color: #000000;
    font-size: 14px;
    font-weight: 300;
  }
}

.aboutus {
  max-width: 1217px;
}

.career-view {
  margin-top: 110px;
}

.gallery {
  .cust-container {
    margin-top: 50px;
  }
}

.store-locator-page {
  margin-top: 30px;
  .store-search {
    padding: 18px;
    background-color: #c2171d;
    border-radius: 5px;
    .icon-search {
      position: absolute;
      top: 32%;
      right: 10px;
      font-size: 24px;
      color: #c2171d;
    }
    h2 {
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
    input {
      border-radius: 6px;
      border: 1px solid #707070;
      background-color: #ffffff;
      padding: 16px 13px;
      width: 100%;
      padding-right: 50px;
      &::placeholder {
        color: #000 !important;
      }
    }
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px auto;
    width: 100%;
    max-width: 350px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #fff;
  }

  .separator:not(:empty)::before {
    margin-right: 0.25em;
  }

  .separator:not(:empty)::after {
    margin-left: 0.25em;
  }

  .acdc-express {
    margin-top: 22px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;

    h2 {
      color: #c2171d;
      font-size: 18px;
      font-weight: 700;
    }

    p {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.cart-responsnive {
  border-radius: 0px;
}

.pro-price {
  color: #c2171d;
  font-size: 14px;
  font-weight: 400;
}
.login-form.modal-div {
  .or {
    color: $app-secondary-color;
    text-align: center;
    padding: 15px 0px;
    font-size: 22px;
    font-weight: 700;
  }
  .auth-btn {
    height: 40px;
    text-align: center;
    background-color: transparent;
    color: #000;
    line-height: 40px;
    padding: 0 10px;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0;
    border: 1px solid #000;
  }
  .forget-link {
    font-size: 14px;
    color: #a2a2a2;
  }
  .bottom-links {
    margin: auto;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    text-align: center;

    a {
      display: inline-block;
      line-height: 25px;
      text-transform: capitalize;
      font-size: 14px;
      border-radius: 0px;
      padding: 0 15px;
      text-align: center;
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
      text-decoration: none;
      transition: all 100ms ease-in-out;

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}
.login-modal-body {
  padding: 2.5rem !important;
}
.store-modal {
  .welcome {
    color: #c2171d;
    font-size: 16px;
  }
  .modal-body {
    text-align: center;
    p {
      color: #000000;
      font-size: 16px;
    }
    img {
      &:hover {
        box-shadow: none !important;
      }
    }
    .near-store {
      border-radius: 5px;
      border: 1px solid #000000;
      padding: 18px 12px;
      background-color: #000000;
    }
    .user-login {
      img {
        transform: rotate(90deg);
        position: absolute;
        top: 14px;
        right: 20px;
      }
      span {
        color: #c2171d;
        font-size: 14px;
        font-weight: 700;
      }
      .new-dropdown {
        top: -33px;
        left: -76px;
      }
      background: white;
      border-radius: 6px;
    }
  }
}
.account-info {
  .form-group {
    .ctrl-inner-wrapper {
      .form-control {
        border-radius: 5px !important;
        border: 1px solid #3f3f3f !important;
        padding-left: 10px;
      }
    }
  }
}
.store-dropdown {
  top: -20px !important;
  @media all and (max-width: 991px) {
    top: 10px !important;
  }
}
.sc-bZQynM {
  align-items: center;
  height: 100%;
}
.main-carousel {
  .home-carousel {
    margin-top: 107px;
  }
}
.col-half-offset {
  margin-left: 1.166666667%;
}
.rupee {
  width: 160px;
}
.wrap-text {
  white-space: nowrap;
}
select {
  cursor: pointer;
}
img,
.product-image-wrapper .product-image,
button {
  transition: transform 0.45s cubic-bezier(0.25, 0, 0, 1),
    box-shadow 0.45s cubic-bezier(0.25, 0, 0, 1);
  will-change: box-shadow;
  // &:hover {
  //   box-shadow: 0 0.5rem 1.5rem #00000026;
  // }
}
.discount-div {
  background-color: #c2171d;
  border-radius: 12px;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  text-align: center;
  color: white;
}
.main_discount-div {
  background-color: #c2171d;
  border-radius: 12px;
  padding: 5px;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 90px;
  height: 90px;
  font-size: 24px;
  text-align: center;
  color: white;
}
.price-loading {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #b2b2b2 !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
#cookie-notice .cookie-notice-container {
  display: block;
}
#cookie-notice,
#cookie-notice * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cookie-notice.cookie-notice-visible.cn-effect-fade,
#cookie-notice.cookie-revoke-visible.cn-effect-fade {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
#cookie-notice.cn-effect-fade {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

#cookie-notice {
  position: fixed;
  min-width: 100%;
  height: auto;
  z-index: 100000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  left: 0;
  text-align: center;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto,
    'Helvetica Neue', sans-serif;
}
#cookie-notice .cn-button:not(.cn-button-custom) {
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto,
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 0 0 10px;
  text-align: center;
  text-transform: none;
  display: inline-block;
  cursor: pointer;
  touch-action: manipulation;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 8.5px 10px;
  line-height: 1;
  color: inherit;
}
.cn-position-top {
  top: 0;
}
.cookie-notice-container {
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.cn-text-container,
.cn-buttons-container {
  display: inline-block;
}

.home-special-product {
  height: 81%;
  border-right: 5px solid #a3cd39;
  div {
    height: 100%;
  }
  h1 {
    font-size: 4.38rem;
  }
  span {
    color: $app-secondary-color;
  }
  p {
    font-size: 1.5rem;
  }
}

.agritrack-info-home-section {
  h1 {
    font-size: 1.88rem;
    span {
      color: $app-secondary-color;
    }
  }
  p {
    font-size: 1.25rem;
  }
}

.covid-text {
  margin-left: 400px;
}

.cart-note-box {
  width: 20px;
  height: 20px;
  background-color: red;
  margin-right: 5px;
}

.rating-comp {
  height: 85%;
  border-right: 5px solid #a3cd39;
  margin: 3rem 1rem;
}

.contact-carousel {
  .bghjHX {
    margin: 0px;
  }
  .div1 {
    background: #ededed;
    // background-size: 100% 100%;
    height: 345px;
    width: 100%;
    z-index: 1;
    text-align: center;
    // background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // box-shadow: 10px 0 5px -2px #c2171d;
    p {
      // border: 1px solid #fff;
      // padding: 5px 28px;

      color: #fff;
      font-weight: 300;
      // margin-top: 68px;
      font-size: 26px;
      letter-spacing: 5px;
      width: -moz-fit-content;
      width: fit-content;
    }

    .shop-btn {
      padding-top: 1.5rem !important;
      border: 0px;
      text-align: left;
      background: #a3cd39;
      color: #3a3a3a;
      font-weight: 900;
      padding: 5px 28px;
      letter-spacing: 3px;
      border-radius: 3px;
      width: 560px;
      position: absolute;
      bottom: 55px;
      left: 405px;
      transform: translate(-50%, 0px);
      transition: transform 0.45s cubic-bezier(0.25, 0, 0, 1),
        box-shadow 0.45s cubic-bezier(0.25, 0, 0, 1);
      will-change: box-shadow;
      &:hover {
        // transform: translate3D(0, -2px, 0);
      }
    }
  }

  .div2 {
    background: no-repeat 0px -5px;
    opacity: 1;
    background-size: 100% 100%;
    height: 205px;
    width: 50%;
  }
}
